<template>
  <div id="home-view">
    <div class="home-header">
      <HomeHeader/>
    </div>
    <div class="cover_layer">
<!--
       <div class="load-img">
         <img src="../assets/kfc-unity.jpg" alt="">
       </div>
-->
      <div class="video">
        <video id="v1" v-if="!badBrowser()"
               preload="auto"
               autoplay
               muted
               playsinline
               oncontextmenu="return false"
               onselectstart="return false"
               ondragstart="return false" onbeforecopy="return false">
          <source id="source" src="@/assets/1.mp4" type="video/mp4"/>
        </video>
        <div v-if="badBrowser()" style="height: 100%; width: 100%" id="videoWrapper"></div>
      </div>
      <div class="text">
        <div class="en">Kung Fu Connects The World</div>
        <div class="zh">功夫链接世界</div>
      </div>
    </div>

    <div>
      <New class="new"/>
<!--
      <BodyHome/>
-->
    </div>
  </div>
  <div>
    <BaseFooter/>
  </div>
</template>

<script>

import BaseFooter from "@/components/FooterHome";
import HomeHeader from "@/components/HeaderHome";
import BodyHome from "@/components/BodyHome";
import New from "@/components/NewModal";
import JSMpeg from "@cycjimmy/jsmpeg-player";

export default {
  name: 'HomeView',
  components: {BodyHome, HomeHeader, BaseFooter,New},
  methods: {
    badBrowser() {
      let ua = navigator.userAgent;
      //          oppo
      let arr = ['HeyTapBrowser', 'baidu', 'MQQBrowser', 'MiuiBrowser', 'MZBrowser', 'HuaweiBrowser'];
      for (let i = 0; i < arr.length; i++) {
        if (ua.indexOf(arr[i]) !== -1) {  // 不支持的浏览器
          return true;
        }
      }
      return false;
    },
    renderByVideo() {
      let arr = [require('@/assets/1.mp4'), require('@/assets/2.mp4'), require('@/assets/5.mp4')];
      let x = 1;

      let source = document.getElementById("source");
      let video = document.getElementById("v1");

      video.addEventListener('play', function () {
        source.src = arr[x % arr.length];
      });
      video.addEventListener('ended', function () {
        video.load();
        video.play();
        x++;
      }, false);
    },
    renderByJSMpeg() {
      new JSMpeg.VideoElement(
          '#videoWrapper',
          // 'https://file.kungfuchain.com/6.ts',
          // 'https://dev.gfl.cn/6.ts',
          './6.ts',
          {
            autoplay: true,
            loop: true,
            control: false,
          },
          {
            audio: false
          }
      );
    }
  },
  mounted() {
    this.badBrowser() ? this.renderByJSMpeg() : this.renderByVideo()
  }
}
</script>


<style lang="scss" scoped>
/* 宽度小于等于600px使用 手机 */
.video {
  z-index: -2;
}

.text {
  z-index: -1;
}
.load-img{
  position: absolute;
  left: 10vw;
  top:10vh;
  img{
    width: 10vw;
    height: auto;
  }
}
::v-deep .wrap.new .content{
  min-height: auto;
  padding:3vw;
}

@media screen and (max-width: 1450px){
  .new ::v-deep .list img{
    width: 10vw;
    height: 10vw;
  }
  ::v-deep .wrap{
    min-height: auto;
  }
}
@media screen and (max-width: 950px){
  ::v-deep .swiper-img,::v-deep .swiper{
    width:80vw;
    height:80vw;
    object-fit: cover;
  }
  ::v-deep   .list{
    //display: block;
    width:80vw;
    height: 100vw;
    margin-left: 0;
    margin-top: 3vh;
    .item{
      display: block;
      img{
        width: 100%;
        height: 100vw;
      }
      dd{
        padding-left: 0;
      }

    }
  }

  ::v-deep .content{
    flex-direction: column;
  }

}

@media screen and (max-width: 650px) {
  .load-img {
    top: 5vh;
    left: 5vh;
  }
    #home-view {
    display: grid;
      overflow-x:hidden;

    .home-header {
      //background: url("@/assets/header.png") no-repeat;
      height: 260px;
      background-size: 100% 100%;
    }

    .cover_layer {
      display: grid;

      .video {
        width: 100%;
        height: 100%;

        video {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .text {
        background: rgba(0, 0, 0, 0.5);
        color: white;
        display: grid;
        justify-items: center;
        font-weight: bold;
        row-gap: 1vh;

        width: 100%;
        height: 100%;

        .en, .zh {
          font-size:18px;
        }

        .en {
          align-self: end;
          white-space: nowrap;
        }
      }
    }

    .home-header, .cover_layer {
      grid-area: 1 / 1;

      .video, .text {
        grid-area: 1 / 1;
      }
    }
  }
}

/* 宽度大于600px使用 */

@media screen and (min-width: 651px) {
  #home-view {
    display: grid;
  overflow-x:hidden;
    .home-header {
      //background: url("@/assets/header.png") no-repeat;
      height: 700px;
      background-size: 100% 100%;
    }

    .cover_layer {
      display: grid;

      .video {
        width: 100%;
        height: auto;
        //height: 100vh;
        video {
          object-fit: fill;
          width: 100%;
          height: 100%;
        }
      }

      .text {
        background: rgba(0, 0, 0, 0.5);
        color: white;
        display: grid;
        grid-template-rows: .4fr .4fr;
        justify-items: center;
        font-weight: bold;
        row-gap: 3.5vh;
        width: 100%;
        height: 100%;

        .en, .zh {
          font-size: 5vh;
        }

        .en {
          align-self: end;
        }

        .zh {
          letter-spacing: 0.2vw;
        }
      }
    }

    .home-header, .cover_layer {
      grid-area: 1 / 1;

      .video, .text {
        grid-area: 1 / 1;
      }
    }
  }
}
</style>
