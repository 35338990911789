import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/base.css'
import './assets/css/main.scss'
import './assets/css/font.scss'
import VueLazyload from "vue-lazyload";
import Vue3Marquee from 'vue3-marquee'

createApp(App).use(router).use(Vue3Marquee).use(VueLazyload,{
    preLoad: 1.3, //预加载的宽高比
    // loading: loadimage, //图片加载状态下显示的图片
    // error: errorimage, //图片加载失败时显示的图片
    attempt: 1, // 加载错误后最大尝试次数
}).mount('#app')
