const matches=[
    {
        cn:"中俄武术套路交流赛暨纪念中俄建交75周年活动在湛江市少林学校隆重举行",
        en:"Sino-Russian Martial Arts Exchange and 75th Anniversary of Diplomatic Ties at Zhanjiang Shaolin School",
        src:"https://wushu-api.wushu.com.cn/upload/20241003/172792561886613.jpg",
        link:"https://www.wushu.com.cn/#/inform/informDetail?id=2805"

    },
    {
        cn:"第十届亚洲武术锦标赛在中国澳门圆满落幕",
        en:"The 10th Asian Wushu Championships concluded successfully in Macau, China.",
        src:"https://wushu-api.wushu.com.cn/upload/20240918/172662876364965.jpg",
        link:"https://www.wushu.com.cn/#/inform/informDetail?id=2770"

    },
    {
        cn:"2024年全国武术套路冠军赛在天津开赛",
        en:"The 2024 National Wushu Routine Championship kicks off in Tianjin.",
        src:"https://wushu-api.wushu.com.cn/upload/20240927/172739749900462.jpg",
        link:"https://www.wushu.com.cn/#/inform/informDetail?id=2795"

    },
    {
        cn:"“全民健身 太极同行“2024年全民健身线上运动会暨第三届全球太极拳网络大赛竞赛规程",
        en:"2024 National Fitness Online Games and the 3rd Global Tai Chi Online Competition",
        src:"https://resource.ttplus.cn/publish/app/data/2022/05/12/423986/602e3220-66a9-4527-b9fc-68a8bdc85161.jpg",
        link:"https://www.wushu.com.cn/#/globalSearch/detail?id=2602"

    },

]
/*
const entries = Object.entries(matches0);
const sortedEntries = entries.sort((a, b) => b[0] - a[0]);
const matches=Object.fromEntries(sortedEntries);
*/


module.exports.matches = matches;