<template>
  <div  :class="['container',{'foreign':foreign}]"  id="headerContainer"  >
    <div class="header" id="header">
      <div class="h-link">
<!--
        <div class="h-icon"></div>
        <div class="h-title" ><div>{{foreign ? 'KUNGFU CHAIN': '功夫链'}}</div><div class="h-r">®</div></div>
-->
<!--        <img class="h-link-kfl" src="../assets/introduce/icons/icon_double.png" alt="">-->
      </div>
      <div class="link-navigate">
        <div class="home">
          <router-link to="/">{{foreign ? 'Home': '首页'}}</router-link>
        </div>
<!--
        <div class="world">
          <router-link to="/about">{{foreign ? 'About Us': '关于'}}</router-link>
        </div>
-->
<!--
        <div class="about">
          <router-link to="/world">{{foreign ? 'World': '全球化'}}</router-link>
        </div>
-->
        <div class="about" v-if="!foreign">
          <router-link to="/introduce">{{foreign ? 'World': '段品考功大赛'}}</router-link>
        </div>
<!--
        <div class="about" v-if="!foreign">
          <router-link :class="{'router-link-active':$route.path.includes('news')}" to="/news">{{foreign ? 'World': '新闻'}}</router-link>
        </div>
-->
        <div class="about"  v-if="!foreign" >
          <router-link to="/dpz" :class="{'router-link-active':$route.path.includes('dpz')}" >少林功夫段品制</router-link>
        </div>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import {useRouter} from 'vue-router'
export default {
  name: "HeaderHome",
  data(){
    return {
      foreign:false
    }
  },
  mounted(){
    const router = useRouter();
    let url=router.currentRoute.value;

    if(url.fullPath.includes('world')){
      this.foreign=true
    }
  },
  methods: {
    /* 点击三个点，弹出路由 */
    showMenu: () => {
      let menu = document.getElementById("menu");
      let header = document.getElementById("header");
      menu.style.display = 'grid';
      header.style.display = 'none';
      let container = document.getElementById("headerContainer");
      container.style.padding = '0';


      let point = document.getElementById("point");
      point.style.display = 'none';
    },
    close: () => {
      let menu = document.getElementById("menu");
      let header = document.getElementById("header");
      header.style.display = 'block';
      menu.style.display = 'none';
      let point = document.getElementById("point");
      point.style.display = 'grid';
      let container = document.getElementById("headerContainer");
      container.style.padding = '0 2vw';

    }
  }
}
</script>

<style lang="scss" scoped>
.h-link-kfl{
  width: 80px;
  margin-left: 20px;
}
.h-link{
  display: flex;
  align-items: center;
}
.home-header>.container{
  padding: 0 2vw;
}
.header{
  display: flex;
  justify-content: space-between;
  .h-title{
    div:first-child{
      margin: 0 5px;
      align-self: center;
    }
  }

}
.container .r2,.container .r1 .close{
  cursor: pointer;
}
.about-header{
  padding: 0 2vw;
}

.h-title, a {
  color: white;
}
.router-link-active {
  //font-weight: bold;
  opacity: 1;
  border-bottom: 3px solid white;
}
.foreign .link-navigate{
  grid-template-columns: 10fr 100px 36px;
}
.link-navigate{
  display: flex;
  justify-items: center;
  column-gap: 18px;
  place-items: start center;
}
  .container{
    &.foreign{
      .header
      .h-title{
        font-size: 20px;
        white-space: nowrap;
        .h-r{
          line-height: 70px;
          font-size: 16px;
        }
      }
    }
    .r1 {
      display: none;
    }
  }
  .header {
    display: flex;
    line-height: 10vh;
    column-gap: 0.8vw;
    font-weight: bold;
    margin: 0 10vw;
    z-index: 999;
    align-items: center;
    .h-icon {
      width: 30px;
      height: 30px;
      background: url(@/assets/logo.png) no-repeat;
      background-size: 100%;
      align-self: center;
      justify-self: end;
    }

    .h-title {
      display: flex;
      font-size: 30px;
      font-family: 黑体, cursive;
      .h-r {
        line-height:70px;
        font-size: 16px;
        font-family: 黑体, cursive;
      }
    }

    .home {
      justify-self: end;
    }
    .home, .about,.world {
      display: block;
      margin-top: -3px;
    }
  }
  .router {
    display: none;
  }


</style>