<template>
  <div class="body">
    <div class="left_top">
      <div class="title">
        功夫链<div class="r">®</div>&nbsp;APP
      </div>
      <div class="desc pc">
        <p>功夫链致力于AI机器学习、图像算法、脑神经网络技术，通过AR互娱和游</p>
        <p>戏化内容，帮助全球30亿用户健康生活、强身健体。</p>
      </div>
      <div class="desc mobile">
        <p>功夫链致力于AI机器学习、图像算法、脑神经网络技术，通过AR互娱和游戏化内容，帮助全球30亿用户健康生活、强身健体。</p>
      </div>
    </div>
    <div class="left_button">
      <div class="android_qr"></div>
      <div class="ios_qr"></div>
      <div class="ios"></div>
      <div class="android"></div>
    </div>
    <div class="right">
      <swiper
          :effect="'cube'"
          :grabCursor="true"
          :cubeEffect="{
      shadow: true,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
      modifier: 2
    }"
          :pagination="{
            clickable:true
          }"
          :loop="true"
          :modules="modules"
          class="mySwiper"
      >
        <swiper-slide><img class="swiper-img" src="@/assets/images/swiper/1.png"/></swiper-slide>
        <swiper-slide><img class="swiper-img" src="@/assets/images/swiper/2.png"/></swiper-slide>
        <swiper-slide><img class="swiper-img" src="@/assets/images/swiper/3.png"/></swiper-slide>
        <swiper-slide><img class="swiper-img" src="@/assets/images/swiper/1.png"/></swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-cube";
import "swiper/css/pagination";


// import required modules
import {EffectCube, Pagination} from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectCube, Pagination],
    };
  },
};
</script>

<style>
.swiper-pagination-bullet {
  background: white;
}
</style>

<style lang="scss" scoped>
.swiper-img{
  width: 100%;
}

/* 宽度小于等于600px使用 手机 */
@media screen and (max-width: 1050px){
   .android,.ios{
     transform: scale(0.65);
     margin-top: -20px;
   }

}
@media screen and (max-width: 600px) {

  .body {
    background-color: black;
    color: white;
    display: grid;
    grid-template-areas:
        'left_top'
        'right'
        'left_button'
    '.'
  ;
    row-gap: 8vw;
  }

  /* =================================================================== */

  .left_top {
    grid-area: left_top;
    display: grid;
    row-gap: 6vw;
    padding: 5vw;

    .title {
      align-self: end;
      font-size: 4.5vw;
      font-family: 黑体, cursive;
      .r {
        display: inline-block;
        font-size: 1vw;
        vertical-align:text-top;
        font-family: 黑体, cursive;
      }
    }

    .pc {
      display: none;
    }
    .mobile {
      font-size: 4vw;
    }
  }

  /* =================================================================== */

  .left_button {
    display: grid;
    justify-items: center;
    align-self: start;
    grid-area: left_button;
    row-gap: 6vw;
    margin-top: 30px;
    grid-template-areas:
        'ios ios_qr'
        'android android_qr'
  ;

    .android {
      grid-area: android;
      background: url("@/assets/android.png") no-repeat;
      background-size: 194px 61px;
      min-height: 61px;
      min-width: 194px;
      margin-top: 0;
      transform: scale(0.8);
    }

    .ios {
      grid-area: ios;
      background: url("@/assets/ios.png") no-repeat;
      background-size: 194px 61px;
      min-height: 61px;
      min-width: 194px;
      margin-top: 0;
      transform: scale(0.8);
    }

    .android_qr {
      grid-area: android_qr;
    }
    .ios_qr {
      grid-area: ios_qr;
    }
    .android_qr, .ios_qr {
      background: url("@/assets/qr.png") no-repeat;
      background-size: 100%;
      justify-self: start;
      //height: 61px;
      //width: 61px;

      height: 61px;
      width: 61px;
    }
  }

  .right {
    grid-area: right;
    justify-items: center;
    .swiper {
      //width: 239px;
      //height: 474px + 46px;
      //min-height: 474px + 46px;
      width: 180px;
      height: 430px;
      min-height: 430px;
      img {
        width: 180px;
        height: 390px;
      }
    }
  }
}

/* 宽度大于600px使用 */
@media screen and (min-width: 600px) {
  .body {
    background-color: black;
    color: white;
    height: 65vh;
    min-height: 600px;

    display: grid;
    grid-template: 3fr 4fr / auto 40% 40%;

    row-gap: 4vh;
    grid-template-areas:
  '. left_top right'
  '. left_button right';
  }

  /* =================================================================== */

  .left_top {
    //border: 1px solid red;
    grid-area: left_top;
    //align-self: end;

    display: grid;
    row-gap: 1vw;

    .title {
      font-size: 2vw;
      align-self: end;
      font-family: 黑体, cursive;
      .r {
        display: inline-grid;
        font-size: 1vw;
        vertical-align:text-top;
        font-family: 黑体, cursive;
      }
    }

  }
  .mobile {
    display: none;
  }
  /* =================================================================== */

  .left_button {
    //border: 1px solid red;
    grid-area: left_button;
    display: grid;
    grid-template: 2fr 3fr / 30% 60%;
    grid-column-gap: 1vw;
    justify-items: center;
    row-gap: 1vw;
  }

  .android {
    background: url("@/assets/android.png") no-repeat;
    background-size: 194px 61px;
    min-height: 61px;
    min-width: 194px;
  }

  .ios {
    background: url("@/assets/ios.png") no-repeat;
    background-size: 194px 61px;
    min-height: 61px;
    min-width: 194px;
  }

  .android_qr, .ios_qr {
    background: url("@/assets/qr.png") no-repeat;
    background-size: 100%;
    //height: 61px;
    //width: 61px;

    height: 122px;
    width: 122px;
  }

  /* =================================================================== */

  .right {
    grid-area: right;
    display: grid;
    justify-self: start;

    .swiper {
      width: 239px;
      height: 474px + 46px;
      align-self: center;
    }
  }
}
</style>