<!--
 		 	<div style="width:300px;margin:0 auto; padding:20px 0;">
		 		<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602007011" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">沪公网安备 31010602007011号</p></a>
		 	</div>


 -->
<template>
  <div class="footer-wrap">
  <div id="footer">
    <div class="mobile">
      <p>Powered by 功夫链（上海）体育文化发展有限公司 版权所有 &nbsp;</p><p>
      <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602007011">沪公网安备 31010602007011号&nbsp; </a>
      <a class="text-color" href="https://beian.miit.gov.cn" target="_blank">沪ICP备2022005659号-1</a></p>
    </div>
    <div class="pc">
      <span>Powered by 功夫链（上海）体育文化发展有限公司 版权所有 &nbsp;</span>
      <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602007011">沪公网安备 31010602007011号&nbsp; </a>
      <a class="text-color" href="https://beian.miit.gov.cn" target="_blank">沪ICP备2022005659号-1</a>
    </div>
    <div class="copyright"><span class="word_little_space">Copyright © 2024 All Rights reserved</span><img  class="h-link-kfl" src="../assets/introduce/icons/icon_double.png" alt=""  style="width: 50px;height: auto;margin-left: 10px;"/></div>
  </div>
  </div>
</template>

<script>
export default {
  name: "FooterHome"
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/footer.scss";
.footer-wrap{
  background-color: rgb(38, 38, 38);
}
#footer {
  color: white;
}
.copyright{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>