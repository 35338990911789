<template>
  <div  :class="['container',{'foreign':foreign}]"  id="headerContainer" >
    <div class="header" id="header">
      <div class="h-link">
<!--
      <div class="h-icon"></div>
      <div class="h-title" ><div>{{foreign ? 'KUNGFU CHAIN': '功夫链'}}</div><div class="h-r">®</div></div>
-->
<!--        <img class="h-link-kfl" src="../assets/introduce/icons/icon_double.png" alt="" onclick="window.location.assign('/')">-->
        <div class="load-img" v-if="isHome"  @click="loadApp">
<!--          <img src="../assets/kfc-unity.jpg" alt="">-->
              <img :src="imgSrc" alt="">
        </div>

      </div>
      <div class="link-navigate">
        <div class="home">
          <router-link to="/">{{foreign ? 'Home': '首页'}}</router-link>

        </div>
<!--
        <div class="world">
          <router-link to="/about">{{foreign ? 'About Us': '关于'}}</router-link>
        </div>
-->
<!--
        <div class="about">
          <router-link to="/world">{{foreign ? 'World': '全球化'}}</router-link>
        </div>
-->
        <div class="about" v-if="!foreign">
          <router-link to="/introduce">{{foreign ? 'World': '段品考功大赛'}}</router-link>
        </div>
<!--
        <div class="about" v-if="!foreign">
          <router-link  :class="{'router-link-active':$route.path.includes('news')}"  to="/news">{{foreign ? 'World': '新闻'}}</router-link>
        </div>
-->
        <div class="about" v-if="!foreign">
          <router-link to="/dpz">{{foreign ? 'World': '少林功夫段品制'}}</router-link>
        </div>
        <div class="about" v-if="!foreign">
          <router-link to="/game">小游戏</router-link>
        </div>
      </div>
    </div>
     <div>
       <div id="menu" class="router r1">
         <div class="home">
           <router-link to="/">{{foreign ? 'Home': '首页'}}</router-link>
         </div>
<!--
         <div class="world">
           <router-link to="/about">{{foreign ? 'About Us': '关于'}}</router-link>
         </div>
-->
<!--
         <div class="about">
           <router-link to="/world">{{foreign ? 'World': '全球化'}}</router-link>
         </div>
-->
         <div class="about" v-if="!foreign">
           <router-link to="/introduce">段品考功大赛</router-link>
         </div>
         <div class="world" v-if="!foreign">
           <router-link to="/dpz">少林功夫段品制</router-link>
         </div>
         <div class="world" v-if="!foreign">
           <router-link to="/game">小游戏</router-link>
         </div>
         <div @click="close" class="close">×</div>
       </div>
       <div id="point"  class="router r2">
         <div @click="showMenu">
           <span class="point"></span>
           <span class="point"></span>
           <span class="point"></span>
         </div>
       </div>
     </div>
  </div>
</template>

<script>
import {useRouter} from 'vue-router'
export default {
  name: "HeaderHome",
  data(){
    return {
      foreign:false,
      isHome:false,
      imgSrc:"",
    }
  },
  mounted(){
    const that=this;
    const router = useRouter();
    let url=router.currentRoute.value;
    console.log(url)

    if(url.fullPath.includes('world')){
      this.foreign=true
    }
    if(url.fullPath=="/"){
      this.isHome=true
    }

    that.resize();
    window.onresize = function () {  //监听屏幕的改变
      setTimeout( that.resize, 10)
    };
  },
  methods: {
    resize(){
      if(window.innerWidth > 600){
        this.hideNav()
        this.imgSrc=require("../assets/kfc-unity.jpg");
      }else{
        let point = document.getElementById("point");
        point.style.display = 'block';
        this.imgSrc=require("../assets/icon_logo_new.png");
      }
    },
    loadApp(){
      if(window.innerWidth > 600){return;}
      window.location.href="https://www.kungfuchain.com/share_h5_2/views/loadApp.html?link=1"
    },
    /* 点击三个点，弹出路由 */
    showMenu: () => {
      let menu = document.getElementById("menu");
      let header = document.getElementById("header");
      menu.style.display = 'grid';
      header.style.display = 'none';
      let container = document.getElementById("headerContainer");
      container.style.padding = '0';


      let point = document.getElementById("point");
      point.style.display = 'none';
    },
    close: () => {
      let menu = document.getElementById("menu");
      let header = document.getElementById("header");
      header.style.display = 'block';
      menu.style.display = 'none';
      let point = document.getElementById("point");
      point.style.display = 'grid';
      let container = document.getElementById("headerContainer");
      container.style.padding = ' 2vw';

    },
     hideNav:()=>{
       let menu = document.getElementById("menu");
       let header = document.getElementById("header");
       header.style.display = 'flex';
       menu.style.display = 'none';
       let point = document.getElementById("point");
       point.style.display = 'none';
       let container = document.getElementById("headerContainer");
       container.style.padding = '0 ';

     }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/header.scss";

.h-title, a {
  color: white;
}
.router-link-active {
  //font-weight: bold;
  opacity: 1;
  border-bottom: 3px solid white;
}
.foreign .link-navigate{
  grid-template-columns: 10fr 100px 36px;
}
.link-navigate{
  display: flex;
  justify-items: center;
  column-gap: 0.8vw;
  place-items: start center;
}
.h-link{
  position: relative;
}
.load-img {
  position: absolute;
  /*
  font-size: 0;
  &:after{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,.5);
    z-index: 1;
  }
  */

    &:before{
      display: none;
    content: "Download";
    position: absolute;
    font-size: 1.5vw;
    color: #FFF;
    z-index: 1;
    line-height: 5;
    font-weight: 600;
    animation: animate 2s linear infinite alternate-reverse ;
  }

    width: 10vw;
    left: 10vw;
    top: 1vw;
  img{
    width: 100%;
    opacity: .9;
  }
}
@keyframes animate {
  from{
    top: 0;
    left: 0;
    color: red;
  }to{
       color: #FFF;
       top: 3vw;
       left: 2vw;
     }
}
@media screen and (max-width: 800px){
  .load-img {
    left: 15vw;
  }
}
@media screen and (max-width: 600px){
  .load-img {
    cursor: pointer;
    left: 25vw;
    top: 5vw;
    &:before{
      font-size: 2vw;
      display: block;
    }
  }
}
@media screen and (min-width: 1300px){
  .load-img {
    left: 2vw;
  }
}
@media screen and (max-width: 400px){
  .load-img {
    left: 28vw;
    width: 14vw;
  }
}
</style>